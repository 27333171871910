import React, { Component } from 'react';
import './Header.scss';
import CarouselDisplay from '../Header/CarouselDisplay ';
import NavBar from './NavBar';
import logIn from '../log_in/Log_In'

export  class Header extends Component {

	render() {
		return (
			<div className="wrap">
				<NavBar />
				<CarouselDisplay />
			</div>
		);
	}
}
