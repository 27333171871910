import React, {Component} from 'react';
import NavBar from '../Header/NavBar';
import {Container, Row, Col} from 'react-bootstrap';
import './HowItWorks.scss';
import {useHistory} from 'react-router-dom';
import matchmakingIcon from '../../images/matchingIcon.png';
import pricingIcon from '../../images/pricingIcon.png';
import customerIcon from '../../images/customerServiceIcon.png';
import "./HowItWorks.css";
import matchmakingIconWhite from '../../images/matchingIconWhite.png';
import pricingIconWhite from '../../images/pricingIconWhite.png';
import customerIconWhite from '../../images/customerServiceIconWhite.png';

function HowItWorks() {
  const history = useHistory();

  return (<div>
    <NavBar/>
    <div className="py-5 HowItWorks-page ">
      <div className = "d-flex justify-content-center">
          <div className=" HowItWorks-Card">
            <img src={matchmakingIcon}/>
            <h5 className="how-it-works-h3 text-uppercase">Matchmaking Technology</h5>
            <h6 className="how-it-works-h6 text-muted">Where the magic happens!</h6>
            <hr/>
            <p className="how-it-works-p">This technology uses information provided by millennials about their specific needs and budget on our platform and matches them with the right financial coach based on their needs.</p>
          </div>

          <div className=" HowItWorks-Card">
            <img src={pricingIcon}/>
            <h5 className="how-it-works-h3 text-uppercase">Affordable Pricing</h5>
            <h6 className="how-it-works-h6 text-muted">This is what matters most!</h6>
            <hr/>
            <p className="how-it-works-p">Few financial coaches charge the same price for their services to different customers. With Walletgyde, there are no price discriminations. In fact, customers pay only for the services they need and not for the complete package.</p>
          </div>
        </div>

    </div>
  </div>);
}

export default HowItWorks;
