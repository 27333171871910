import React, {Component} from 'react';
import {FaCheck} from 'react-icons/fa'
import NavBar from '../Header/NavBar';
import {useHistory} from 'react-router-dom';
import './Pricing.scss';
import "./Pricing.css";

function Pricing() {
  const history = useHistory();

  return (<div>
    <NavBar/>
    
    <section className="pricing py-5">
    <div className = "Pricing-Page">
      <div className = "pricing-disclaimer">
        paying for higher tiered content will also let you access all lower priced content.
      </div>
      <div className = "Pricing-Container">
      <div className="">
          <div className="Pricing-division-container">
            <div className="card mb-5 mb-lg-0">
              <div className="card-body">
                <h5 className="card-title text-muted text-uppercase text-center">Basic</h5>
                <h6 className="card-price text-center">FREE</h6>
                <h5 className="card-title text-uppercase text-center">Self-Paced</h5>
                <hr/>
                <ul className="fa-ul">
                  <li>
                    <span className="fa-li"><FaCheck/></span>Unlimited access to walletgyde blog</li>
                  <li>
                    <span className="fa-li"><FaCheck/></span>Tools to identify money problems</li>
                  <li>
                    <span className="fa-li"><FaCheck/></span>Access to Walletgyde staff</li>
                    <li>
                    <span className="fa-li"><FaCheck/></span>Quizzes to test your knowledge</li>

                </ul>
                <a href="/cards" className="btn btn-block btn-primary text-uppercase" onClick={() => history.push("/cards")}>Get Started</a>
              </div>
            </div>
          </div>
          <div className="Pricing-division-container">
            <div className="card mb-5 mb-lg-0">
              <div className="card-body">
                <h5 className="card-title text-muted text-uppercase text-center">Intermediate</h5>
                <h6 className="card-price text-center">$100</h6>
                <h5 className="card-title text-uppercase text-center">Self-Paced</h5>
                <hr/>
                <ul className="fa-ul">
                  <li>
                    <span className="fa-li"><FaCheck/></span>Identify money problem</li>
                  
                  <li>
                    <span className="fa-li"><FaCheck/></span>Setup a customizable budget plan</li>
                  <li>
                    <span className="fa-li"><FaCheck/></span>Streamline all accounts
                  </li>
                </ul>
                <a href="/cards" className="btn btn-block btn-primary text-uppercase" onClick={() => history.push("/cards")}>Get Started</a>
              </div>
            </div>
          </div>
          <div className="Pricing-division-container">
            <div className="card mb-5 mb-lg-0">
              <div className="card-body">
                <h5 className="card-title text-muted text-uppercase text-center">Advanced</h5>
                <h6 className="card-price text-center">$500</h6>
                <h5 className="card-title text-uppercase text-center">Self-Paced</h5>
                <hr/>
                <ul className="fa-ul">
                  <li>
                    <span className="fa-li"><FaCheck/></span>Student loan management</li>
                  <li>
                    <span className="fa-li"><FaCheck/></span>Home buying counseling</li>
                  <li>
                    <span className="fa-li"><FaCheck/></span>Maximize retirement accounts</li>
                  <li>
                    <span className="fa-li"><FaCheck/></span>Maximize employee benefits</li>
                  <li>
                    <span className="fa-li"><FaCheck/></span>Maximizing insurance benefits</li>
                  <li>
                    <span className="fa-li"><FaCheck/></span>Tax filing assistance</li>
                  <li>
                    <span className="fa-li"><FaCheck/></span>Additional video streaming resources</li>
                </ul>
                <a href="/cards" className="btn btn-block btn-primary text-uppercase" onClick={() => history.push("/cards")}>Get Started</a>
              </div>
            </div>
          </div>

          <div className="Pricing-division-container">
            <div className="card mb-5 mb-lg-0">
              <div className="card-body">
                <h5 className="card-title text-muted text-uppercase text-center">Expert</h5>
                <h6 className="card-price text-center">$1,000+
                </h6>
                <h5 className="card-title  text-uppercase text-center">With Coach</h5>
                <hr/>
                <ul className="fa-ul">
 
                  <li>
                    <span className="fa-li"><FaCheck/></span>3-6 months in depth guided coaching</li>
                  <li>
                    <span className="fa-li"><FaCheck/></span>Including video resources</li>
                  <li>
                    <span className="fa-li"><FaCheck/></span>Access to your own finachial coach</li>
                </ul>
                <a href="/cards" className="btn btn-block btn-primary text-uppercase" onClick={() => history.push("/cards")}>Get Started</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </section>
    
  </div>)

}

export default Pricing;
