
//This application sends an email to, _______ from the gmail Walletgyde@gmail.com
//account info for email Pass:WalletGyde28/1 Email: walletGyde@gmail.com



export default {
    USER_ID: 'user_axzUUA4g4YgFuhydsJNiY', 
    SERVICE_ID: 'service_9esysa5',
    PASSWORD: 'WalletGyde28/1'   ,  //user
    TEMPLATE_ID: 'template_uivv2cg',          //templateID
    TARGETEMAIL: 'Info@walletgyde.com',
}