import React, { Component } from 'react';
import quizQuestions from '../../../src/questions.js';
import Quiz from '../Cards/Questions/Quiz';
import { Result } from './Questions/Result';

import { Header } from '../Header/Header';

import NavBar from '../Header/NavBar';

//import mailchimp from "@mailchimp/mailchimp_marketing";
import mandrill from 'mandrill-api/mandrill';

import './Cards.scss';

const mandrillKey = process.env.REACT_APP_MANDRILL_KEY

var m = new mandrill.Mandrill(mandrillKey);
export class Cards extends Component {
    constructor(props) {
        super(props);

        this.state = {
            counter: 0,
            questionId: 1,
            question: '',
            answerOptions: [],
            answer: '',
            result:false,
            answersToQues: [],
            setValue: '',
            type: '',
            email:''
        };
        
        this.handleAnswerSelected = this.handleAnswerSelected.bind(this);
        this.setValue = this.setValue.bind(this)
        this.goBack = this.goBack.bind(this)
        this.sendEmail = this.sendEmail.bind(this)   
    }
      
    componentDidMount() {
        const shuffledAnswerOptions = quizQuestions.map(question =>
            quizQuestions[this.state.counter].answer
        );
        this.setState({
            question: quizQuestions[0].question,
            answerOptions: shuffledAnswerOptions[0],
            type: quizQuestions[0].type
        });
    }

    
    setValue(event) {
        console.log(event.currentTarget.value);
        this.setUserAnswer(event.currentTarget.value);
    }

   
    handleAnswerSelected(event) {
        if (this.state.questionId < quizQuestions.length) {
            setTimeout(() => this.setNextQuestion(), 300);
        } else {
            setTimeout(() => this.setResults(this.getResults()), 300);
        }
    } 

    goBack(event) {
        
        console.log(this.state.counter);
        if (this.state.counter == 0)
            return (<Header />);
        else {
            setTimeout(() => this.setPrevQuestion(), 300);
        }
    }
    setPrevQuestion() {
        const counter = this.state.counter - 1;
        const questionId = this.state.questionId - 1;

        this.setState({
            counter: counter,
            questionId: questionId,
            question: quizQuestions[counter].question,
            answerOptions: quizQuestions[counter].answer,
            answer: '',
            type: quizQuestions[counter].type
        });
        
    }
    setUserAnswer(answer) {
       
        this.setState((state, props) => ({
           
            answer: answer,
            answersToQues: {
                ...state.answersToQues,
                [state.question]: answer
            }
        }));
        
        
    }

    setNextQuestion() {
        const counter = this.state.counter + 1;
        const questionId = this.state.questionId + 1;

        this.setState({
            counter: counter,
            questionId: questionId,
            question: quizQuestions[counter].question,
            answerOptions: quizQuestions[counter].answer,
            answer: '',
            type: quizQuestions[counter].type
        });
    }

    getResults() {
        return true;
 
    }

    setResults(result) {

        
            this.setState({ result: result });
       
    }

    sendEmail() {

        let tempFixEmailData = {
            email: this.state.answersToQues["Email"],
            name: this.state.answersToQues["Name"]
        }
    const date=new Date()
    const message={
        "key": mandrillKey,
        "message": {
            "html": `<h1>Thank you for signing up, ${this.state.answersToQues["Name"]}</h1>` +
            "<p>This concludes our beta test for the WalletGyde API.  When you get a chance, please fill out this quick survey to let us know what you liked, what we can improve, and what we can add to take WalletGyde to the next level.</p>" +
            "<h3><a href='https://www.surveymonkey.com/r/S8BRPDF'>Survey Link</a></h3>",
            "text": "Thank you!",
            "subject": "WalletGyde: Thank You \n",
            "from_email": "donotreply@walletgydeinfo.com",
            "from_name": "WalletGyde Sign-Up\n",
            "to": [
                {
                    "email": this.state.answersToQues["Email"],
                    "name": this.state.answersToQues["Name"],
                    "type": "to"
                }
            ],
            "headers": {
                "Reply-To": "message.reply@example.com"
            },
            "important": false,
            "track_opens": true,
            "track_clicks": true,
            "merge": true,
            "merge_language": "mailchimp",
            "global_merge_vars": [
                {
                    "name": "merge1",
                    "content": "merge1 content"
                }
            ],
            "tags": [
                "signup-success"
            ],
           
            "google_analytics_domains": [
                "walletgyde.com"
            ],
            "google_analytics_campaign": "donotreply@walletgydeinfo.com",
            "metadata": {
                "website": "walletgydecoach.azurewebsites.net"
            },
            "recipient_metadata": [
                {
                    "rcpt": this.state.answersToQues["Email"],
                    "values": {
                        "user_id": 123456
                    }
                }
            ]
        },
        "async": false,
        "ip_pool": "Main Pool",
        "send_at": date.toLocaleString
      }

 
    m.messages.send(message,function(res){
        console.log(res)
    },function(err){
        console.log(err)
    })

    }

    renderQuiz() {
        return (
            <Quiz
                answer={this.state.answer}
                answerOptions={this.state.answerOptions}
                type={this.state.type}
                questionId={this.state.questionId}
                question={this.state.question}
                questionTotal={quizQuestions.length}
                onAnswerSelected={this.handleAnswerSelected}
                setValue={this.setValue}
                goBack={this.goBack}
                sendEmail={this.sendEmail}
            />
        );
    }

    renderResult() {
      return <Result />;
    }
    
    render() {
        return (
            <div className="App">
                <NavBar />
                {this.state.result ? this.renderResult() : this.renderQuiz()}
            </div>
        );
    }
}





	
	
