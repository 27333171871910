
import React, { useState }from 'react';
import PropTypes from 'prop-types';
import {  Form } from 'react-bootstrap';

import { FcLock } from 'react-icons/fc';


function InputBox(props) {
    const [error, setError] = useState("");
   // var error = "";
    return (
        <div className="textAnswer">
            {props.questionId > 7 ? < FcLock size={32} /> : null}
            

                <input key={props.questionId}
                    type={props.type}

                    placeholder={props.content}
                    answer={props.answer}

                onChange={changeValidation}
                    
                    required />
            <span>
                {error}
            </span>
            
            </div>
    );
    function changeValidation(event) {
        event.preventDefault();
        props.setValue(event);
        switch (props.questionId) {
            case 1:
                const validZipcodeRegex = RegExp(
                    /(^\d{5}$)|(^\d{5}-\d{4}$)/
                );
                setError(validZipcodeRegex.test(event.currentTarget.value) ? "" : "Invalid Zipcode");
                break;
            case 8:
                const validateNameRegex = RegExp(
                    /^[a-z ,.'-]+$/i
                );
                setError(validateNameRegex.test(event.currentTarget.value) ? "" : "Name is required");
                break;
            case 9:
                const vaidatePhoneNumberRegex = RegExp(
                    /^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/
                );
                setError(vaidatePhoneNumberRegex.test(event.currentTarget.value) ? "" : "Phone number format incorrect");
                break;
            case 10:
                const validateEmailRegex = RegExp(
                    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                );
                setError(validateEmailRegex.test(event.currentTarget.value) ? "" : "Email address is invalid");
                break;
    }

    }
}


export default InputBox;