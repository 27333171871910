import React, {Component} from 'react';
import NavBar from '../Header/NavBar';
import {Container, Row, Col} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';

import './Contact.scss';
import apiKey from './EmailJSInfo';
import emailjs from 'emailjs-com';






export class Contact extends React.Component {

  constructor(props)
  {
    super(props)
    {
      this.state = {
        name: '',
        email: '',
        message: ''
      }
    }

    this.savePerameter = this.savePerameter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }



  savePerameter(event, key)
  {
    this.setState({[key]: event.target.value})
  }


  
  handleSubmit(event)
  {
    event.preventDefault();
    emailjs.sendForm(apiKey.SERVICE_ID, apiKey.TEMPLATE_ID, event.target, apiKey.USER_ID).then(
    (result) => {alert("Message Sent, We will get back to you shortly", result.text);},
    (error) => {alert("An error occurred, Please try again", error.text);});
    
  }

  render() {
    
    return (<div>
      
      <NavBar/>
      <div className="form-grid">
        <h2 className="form-header">
          Contact WalletGyde
        </h2>
        <form className="form" onSubmit={this.handleSubmit}>
          <label className="form-label" for="name">Name<span>*</span>
          </label>
          <br/>
          <input type="text" id="form-name" name="name" required="required" value ={this.state.name} onChange={event=> this.savePerameter(event, 'name')}/>
          <br/>
          <label className="form-label" for="email">Email<span>*</span>
          </label>
          <br/>
          <input type="email" id="form-email" name="email" required="required" value ={this.state.email} onChange={event=> this.savePerameter(event, 'email')}/>
          <br/>
          <label className="form-label" for="message">Message<span>*</span>
          </label>
          <br/>
          <textarea id="form-message" name="message" required="required" value ={this.state.message} onChange={event=> this.savePerameter(event, 'message')}></textarea>
          <br/>
          <input type="submit" id="form-btn" value="Submit"/>
        </form>
      </div>
    </div>);
  }
}
