import React from 'react';
import {Button, Carousel} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import './Header.scss';
import './CarouselDisplay.scss';
import {Container, Row, Col} from 'react-bootstrap';
import slide1 from '../../images/landing/slide1.png';
import slide2 from '../../images/landing/slide2.png';
import slide3 from '../../images/landing/slide3.png';

function CarouselDisplay(props) {
  const history = useHistory();
  return (
    <Carousel>
      <Carousel.Item >
        <div className="slide" style={{
          backgroundImage: `url(${slide1})`,
        }}>
          <div className="slide-caption">
            <h1>Customize Your Life By Your Own Terms</h1>
            <h2>Match Up With The Right Financial Coach To Learn How</h2>
            <Button className="btn btn-primary py-3 px-4" onClick={() => history.push("/cards")}>
              Get Matched
            </Button>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item >
        <div className="slide" style={{
          backgroundImage: `url(${slide2})`,
        }}>
          <div className="slide-caption">
            <h1>Life Should Be About More Than Bills, Get Rid Of Debt</h1>
            <h2>Match Up With The Right Financial Coach To Learn How</h2>
            <Button className="btn btn-primary py-3 px-4" onClick={() => history.push("/cards")}>
              Get Matched
            </Button>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item >
        <div className="slide" style={{
          backgroundImage: `url(${slide3})`,
        }}>
          <div className="slide-caption">
            <h1>Hate Dealing With Your Money? No Problem!</h1>
            <h2>Match Up With The Right Financial Coach To Learn How</h2>
            <Button className="btn btn-primary py-3 px-4" onClick={() => history.push("/cards")}>
              Get Matched
            </Button>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}
export default CarouselDisplay;
