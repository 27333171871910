var questions = [
    {
        id: 1,
        question: "What is your location?",
        answer: [{ content: "Enter zip code"}],
        options: 1,
        type: "number"
    },
      {
        id: 2,
        question: "What is your main financial concern?",
        answer: [{ content: "Debt", checked: "checked" }, { content: "Credit" }, { content:"Savings"}],
        options: 3,
        type: "radio"
    },
    {
        id: 3,
        question: "Do you  have a financial budget?",
        answer: [{ content: "Yes" }, { content: "No", checked: "checked"  }, { content: "Sort of (Kind of)" }],
        options: 3,
        type: "radio"
    },
    {
        id: 4,
        question: "What is your definition of financial success?",
        answer: [{ content: "Debt-Free" }, { content: "Healthy financials" }, { content: "Retirement" }, { content: "Having choices in life", checked: "checked"  }, { content: "Having a mansion" }, { content: "Having nice things" }, { content: "All of the above" }],
        options: 7,
        type: "radio"
    },
    {
        id: 5,
        question: "What financial goal would you like to see first accomplished?",
        answer: [{ content: "Pay off credit card debt" }, { content: "Pay off mortgage debt" }, { content: "Build emergency savings" }, { content: "Build investments" }, { content: "Learn how to budget better" }, { content: "All of the above", checked: "checked"  }],
        options: 7,
        type: "radio"
    },
    {
        id: 6,
        question: "Are you covered in the event of a catastrophe, emergency, or lawsuit?",
        answer: [{ content: "Yes" }, { content: "No" }, { content: "I don't know", checked: "checked"  }],
        options: 3,
        type: "radio"
    },
    {
        id: 7,
        question: "What is your annual income? ",
        answer: [{ content: "Below 40k" }, { content: "40k-60k", checked: "checked"  }, { content: "60k-80k" }, { content: "80k +" }, { content: "I don't want to answer" }],
        options: 5,
        type: "radio"
    },
    {
        id: 8,
        question: "Name",
        answer: [{ content: "Name" }],
        options: 1,
        type: "text"
    },
     {
        id: 9,
        question: "Phone Number ",
         answer: [{ content: "Phone number" }],
        options: 1,
        type: "number"
    },
    {
        id: 10,

        question: "Email",
        answer: [{ content: "Email ID" }],

        options: 1,
        type: "email"
    }

];

export default questions;
