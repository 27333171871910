import React, {Component, useRef, ReactPropTypes, useState} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import NavBar from '../Header/NavBar';
import {Link, useHistory} from 'react-router-dom';
import logo from '../../images/logoNew.png';
import '../Header/Header.scss';
import './Client_AccountSetup.scss';





class Client_AccountSetup extends Component
{
  

  render()
  {
  return (<div className = "Client_AccountSetup_Page">






  </div>);
  }
}

export default Client_AccountSetup;