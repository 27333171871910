import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Card, Button } from 'react-bootstrap';
import { FcLock } from 'react-icons/fc';
import { useHistory } from 'react-router-dom';



export class Details extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            answer:'',
            details: []
        };
        this.addToArray = this.addToArray.bind(this)
    }
    addToArray(event) {
        this.setUserAnswer(event.currentTarget.value);
    }
    setUserAnswer(answer) {
        this.setState((state, props) => ({

            answer: answer,
            answersToQues: {
                ...state.details,
                [state.answer]: answer
            }
        }));
    }
    render() {
        return (
            <div className="container">
                <div >
                   
                    
                    <Form>
                        <Form.Group className="formBasicEmail"> <FcLock size={32} />
                            <Form.Label><h2 className="question">What is the best way for you to be reached?</h2></Form.Label>
                            <Form.Control type="email" placeholder="Enter email" onChange={this.addToArray} />

                        </Form.Group>
                        <Form.Group className="formBasicPhoneNumber">

                            <Form.Control type="PhoneNumber" placeholder="PhoneNumber" />
                        </Form.Group>

                        <Form.Group className="formBasicFirstName">

                            <Form.Control type="FirstName" placeholder="FirstName" />
                        </Form.Group>
                        <Form.Group className="formBasicLastName">

                            <Form.Control type="LastName" placeholder="LastName" />
                        </Form.Group>
                            

                      
                        <ResultNav />
                    </Form>
               
                </div>
                </div>
        )
    }
}
const ResultNav = (props) => {

    const history = useHistory();
    return (<Button variant="primary" type="button"  onClick={() => history.push("/result")}>
        Submit
    </Button>)
}
Details.propTypes = {
    // setDetails: PropTypes.func.isRequired
   // setValue: PropTypes.func.isRequired,
   // email: PropTypes.string.isRequired
   
};
