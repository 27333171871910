import React, {Component, useRef, ReactPropTypes, useState} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import NavBar from '../Header/NavBar';
import {Link, useHistory} from 'react-router-dom';
import logo from '../../images/logoNew.png';
import '../Header/Header.scss';
import './Create_Account.scss';





class Create_Account extends Component
{
  constructor(props)
  {
    super(props)
    this.state = { email: '', password: '', password2: '', firstName: '', lastName: '', phoneNumber: '', AgreeBox: 'Not_Agree', AccountType: 'user'};
    this.savePerameter = this.savePerameter.bind(this);
  
  }


  checkboxHandler() {
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true
    if (this.state.AgreeBox == 'Agree')
    {
      this.state.AgreeBox = 'Not_Agree'
    }
    else
    {
      this.state.AgreeBox = 'Agree'
    }
    console.log(this.state.AgreeBox)
    // Don't miss the exclamation mark
  }


  savePerameter(event, key)
  {
    this.setState({[key]: event.target.value})
    console.log(this.state.email)
    console.log(this.state.password)
    console.log(this.firstName)
    console.log(this.state.lastName)
    console.log(this.state.AccountType)
  }

  // When the button is clicked
  btnHandler()
  {
    alert('The buttion is clickable!');
  };


  submitClick(event)
  {
    console.log(this.state.email)
    console.log(this.state.password)
    console.log(this.firstName)
    console.log(this.state.lastName)
    console.log(this.state.AccountType)

  }
  

  render()
  {
  return (<div className = "Create_Account_Page">



    <form onSubmit={this.submitClick()} className = "Create_Account_Form" action = "/">
    <img className="Create_Account_Logo" src={logo}/>
    <li className = "create_Account_Lone_Li"><hr className = "Create_Account_Line"></hr></li>
    <div className = "Create_Account_Title"> Create A WalletGyde Account </div>
    <div className = "create_Account_Form_Inside">
      <ul className = "Create_Account_List">
        <li className = "Create_Account_Input">
          <li className = "Create_Account_Form_Type">
            <t1 className = "listLineLeft"> First Name*  </t1> 
          </li>
          <li> <input className = "Create_Account_Input_Box" type="text" defaultValue={this.state.firstName} onChange={event=> this.savePerameter(event, 'firstName')} required/> </li>
        </li>
        <li className = "Create_Account_Input">
          <li className = "Create_Account_Form_Type">
            <t1 className = "listLineLeft"> Last Name*  </t1> 
          </li>
          <li> <input className = "Create_Account_Input_Box" type="text" defaultValue={this.state.lastName} onChange={event=> this.savePerameter(event, 'lastName')} required/> </li>
        </li>
        <li className = "Create_Account_Input">
          <li className = "Create_Account_Form_Type">
            <t1 className = "listLineLeft"> Email*  </t1> 
          </li>
          <li> <input className = "Create_Account_Input_Box" type="email" defaultValue={this.state.email} onChange={event=> this.savePerameter(event, 'email')} required/> </li>
        </li>
        <li className = "Create_Account_Input">
          <li className = "Create_Account_Form_Type">
            <t1 className = "listLineLeft"> Password*  </t1> 
          </li>
          <li> <input className = "Create_Account_Input_Box" type="password" defaultValue={this.state.password} onChange={event=> this.savePerameter(event, 'password')} required/> </li>
        </li>
        <li className = "Create_Account_Input">
          <li className = "Create_Account_Form_Type">
            <t1 className = "listLineLeft"> Confirm Password*  </t1> 
          </li>
          <li> <input className = "Create_Account_Input_Box" type="password" defaultValue={this.state.password2} onChange={event=> this.savePerameter(event, 'password2')} required/> </li>
        </li>
        <li className = "Create_Account_Input">
          <li className = "Create_Account_Form_Type">
            <t1 className = "listLineLeft"> Phone Number  </t1> 
          </li>
          <li> <input className = "Create_Account_Input_Box" type="text" defaultValue={this.state.phoneNumber} onChange={event=> this.savePerameter(event, 'phoneNumber')}/> </li>
        </li>
        <li className = "Create_Account_Input">
        <t1 className = "listLineLeft"> Select Account Type:  </t1> 
        <select className = "drop_Down_Select" defaultValue = {this.state.AccountType} onChange = {event=> this.savePerameter(event, 'AccountType')}>
          <option value="User" value = "User">User</option>
          <option value="Coach" value = "Coach">Coach</option>
        </select>

        </li>
        <li className = "Create_Account_Input">
        <li> <input type="checkbox" id="agree" onChange={event=> this.checkboxHandler()} /> <a href="Terms And Conditions" > I Have Read And Accept The Terms of Service </a></li>
        </li>
        <li className = "Create_Account_Button_Box"><input type="submit" className= "Create_Account_Button" value="Create Account" onclick = "return submitClick();"/></li> 
        
        
      </ul>

      </div>
      <li className = "Create_Account_Disclaimer"> * means field is required </li>
      </form>




  </div>);
  }
}

export default Create_Account;
