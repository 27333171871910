import React from 'react';
import PropTypes from 'prop-types';

import Question from '../Questions/Question';
import QuestionCount from '../Questions/QuestionCount';
import AnswerOption from '../Questions/AnswerOption';
import InputBox from '../Questions/InputBox'
import { Button, Form, FormGroup } from 'react-bootstrap';

import { useHistory } from 'react-router-dom';



function Quiz(props) {

    const history = useHistory();

  function renderAnswerOptions(key) {
    return (
      <AnswerOption
            key={key.content}
            answerContent={key.content}
            answer={props.answer}
            questionId={props.questionId}
            onAnswerSelected={props.onAnswerSelected}
            setValue={props.setValue}
            type={props.type}
            checked={key.checked}
      />
    );
  }
    function renderTextQuestions(key) {


        return (

            <InputBox
                key={key.content}
                answerContent={key.content}
                questionId={props.questionId}
                onAnswerSelected={props.onAnswerSelected}
                setValue={props.setValue}
                type={props.type}
                content={key.content}
                answer={props.answer}
            />
        );

       
    }

    
    function resultButton() {
        history.push("/result");
        props.sendEmail();
    }



  return (
      <div
          className="container transitionContainer"


      >
          <div className="card" key={props.questionId}>
              <QuestionCount counter={props.questionId} total={props.questionTotal} />
              <Question content={props.question} />

              <ul className="answerOptions" value={props.answer} >
                  {props.type === "radio" ? props.answerOptions.map(renderAnswerOptions) : props.answerOptions.map(renderTextQuestions)}
              </ul>
              <ul>

              </ul>
              <div className="button">
                  {props.questionId != 1 && props.questionId != props.questionTotal ? <span className="backButton">< Button onClick={props.goBack} >Back</Button></span> : null}
                  {props.questionId != props.questionTotal ? <Button onClick={props.onAnswerSelected} disabled={!props.answer} > Next question</Button> : null}

                  {props.questionId == props.questionTotal ? <span className="backButton">< Button onClick={resultButton} disabled={!props.answer} >Show Result</Button></span> : null}
              </div>

          </div>
      </div>
  );
}

Quiz.propTypes = {
  answer: PropTypes.string.isRequired,
  answerOptions: PropTypes.array.isRequired,
  question: PropTypes.string.isRequired,
  questionId: PropTypes.number.isRequired,
  questionTotal: PropTypes.number.isRequired,
  checked: PropTypes.string.isRequired,
    onAnswerSelected: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    sendEmail: PropTypes.func.isRequired
};

export default Quiz;
