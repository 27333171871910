import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Header} from '../src/components/Header/Header';
import {Contact} from '../src/components/Contact/Contact';
import HowItWorks from '../src/components/HowItWorks/HowItWorks';
import Log_In from '../src/components/log_in/Log_In';
import Pricing from '../src/components/Pricing/Pricing';
import Create_Account from '../src/components/Create_Account/Create_Account';
import {Cards} from '../src/components/Cards/Cards';
import {Details} from '../src/components/Cards/Questions/Details';
import {Result} from '../src/components/Cards/Questions/Result';
import Client_AccountSetup from '../src/components/Client/Client_AccountSetup';
import './custom.scss'

export default function App() {
  // static displayName = App.name;
  return (
    <Router>
      <Switch>
        <Route path='/' exact="exact" component={Header}/>
        <Route path='/cards' component={Cards}/>
        <Route path='/details' component={Details}/>
        <Route path='/result' component={Result}/>
        <Route path='/pricing' component={Pricing}/>
        <Route path='/contact' component={Contact}/>
        <Route path='/howitworks' component={HowItWorks}/>
        <Route path='/Log_In' component={Log_In}/>
        <Route path='/Create_Account' component={Create_Account}/>
        <Route path='/Client_AccountSetup' component={Client_AccountSetup}/>
      </Switch>
  </Router>);
}
