import React from 'react';
import PropTypes from 'prop-types';

function AnswerOption(props) {

  return (
      <div className="container borderSetting">
        <div className="row  ">
          <div className="col-1">
            <input
              type={props.type}
              className="radioCustomButton "
              name="radioGroup"
              value={props.answerContent}
              id={props.answerContent}
              onChange={props.setValue}
            />
          </div>
          <div className="col-11">
            <label className="radioCustomLabel" htmlFor={props.answerContent} >
              {props.answerContent}
            </label>
          </div>
        </div>
      </div>
  );
}

AnswerOption.propTypes = {
  setValue: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  answerContent: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  checked: PropTypes.string.isRequired,
  //onAnswerSelected: PropTypes.func.isRequired
};

export default AnswerOption;
