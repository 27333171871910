import React, {Component} from 'react';
import {Container, Nav} from 'react-bootstrap';
import './Header.scss';
import logo from '../../images/logoNew.png';
import {Link} from 'react-router-dom';


const NavBar = (props) => {
  return (<Nav className="navbar navbar-expand-lg navbar-light ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
    <Container >
      <div className="logo-header">
        <a className="navbar-brand" href="/">
          <img className="logo" src={logo}/>
        </a>
        <button className="navbar-toggle" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>

      <div className="collapse navbar-collapse" id="ftco-nav">
        <ul className="navbar-nav ml-auto">
          <Link to='/'>
            <li className="nav-item nav-link">HOME</li>
          </Link>
          <Link to='/howitworks'>
            <li className="nav-item nav-link">HOW IT WORKS</li>
          </Link>
          <Link to='/contact'>
            <li className="nav-item nav-link">CONTACT</li>
          </Link>
          <li className="nav-item">
            <a href="http://www.walletgydeblog.com/" target="blank" className="nav-link">BLOG</a>
          </li>
          <Link to='/pricing'>
            <li className="nav-item nav-link">PRICING</li>
          </Link>
          
        <a href="https://app.walletgyde.com/"  className="nav-item nav-link">LOGIN/SIGNUP</a>
          
        </ul>
      </div>
    </Container>
  </Nav>)

}

export default NavBar;
