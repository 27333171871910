import React, {Component} from 'react';
import {Carousel} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {CSSTransitionGroup} from 'react-transition-group';
import NavBar from '../../Header/NavBar';
import coaches from '../../../Coaches';

import {AiFillCheckCircle} from 'react-icons/ai';
//import { BsCircle } from 'react-icons/bs';

export class Result extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      fields: {}
    }

  }
  componentDidMount() {
    const fields = coaches.map(fields => coaches[0].fields);
    this.setState({fields: fields});
    // console.log(this.state.fields);
  }
  render() {
    return (<CSSTransitionGroup className="container result" component="div" transitionName="fade" transitionEnterTimeout={800} transitionLeaveTimeout={500} transitionAppear="transitionAppear" transitionAppearTimeout={500}>
      <div >
        <NavBar/>
        <div className="result-header text-center">
          <h1><AiFillCheckCircle/>Thank You!</h1>
          <h3>Thanks for submitting your request! A financial coach will reach out within 24 hours!</h3>
          <br/>
          <hr></hr>
          <div className="result-nextSteps">
            <h2>Next Steps</h2>
            <h4>1. Free 30 minute consultation from coach</h4>
            <h4>2. Review pricing options</h4>
            <h4>3. Choose a package that bests suits your needs</h4>
          </div>
          <hr></hr>
          <br/>
        </div>
        <Carousel className="coach-slider">
          <Carousel.Item >
            <div className="advisor-details">
              <h3>Local Advisors</h3>
              <div className="courses-container">
                <div className="course">
                  <div className="course-preview-holly"></div>
                  <div className="course-info">
                    <h2>{coaches[0].fields[0].Name}</h2>
                    <h5>{coaches[0].fields[1].summary}</h5>
                    <div className="result-creds">
                      <div className="result-left-clm">
                        <h6>
                          <strong>Experience:</strong>
                          <br/> {coaches[0].fields[2].experience}</h6>
                        <h6>
                          <strong>Certifications:</strong>
                          <br/> {coaches[0].fields[3].Certifications}</h6>
                        <h6>
                          <strong>Email:</strong>
                          <br/>{coaches[0].fields[4].Email}
                        </h6>
                      </div>
                      <div className="result-right-clm">
                        <h6>
                          <strong>Website:</strong>
                          <br/>
                          <a href="https://www.financialimpact.com/" target="_blank">{coaches[0].fields[5].Website}</a>
                        </h6>
                        <h6>
                          <strong>Location:</strong>
                          <br/>{coaches[0].fields[6].Location}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          {/*** YANNEK ***/}
          <Carousel.Item >
            <div className="advisor-details">
              <h3>Local Advisors</h3>
              <div className="courses-container">
                <div className="course">
                  <div className="course-preview-yannek"></div>
                  <div className="course-info">
                    <h2>{coaches[1].fields[0].Name}</h2>
                    <h5>{coaches[1].fields[1].summary}</h5>
                    <div className="result-creds">
                      <div className="result-left-clm">
                        <h6>
                          <strong>Experience:</strong>
                          <br/> {coaches[1].fields[2].experience}</h6>
                        <h6>
                          <strong>Certifications:</strong>
                          <br/> {coaches[1].fields[3].Certifications}</h6>
                      </div>
                      <div className="result-right-clm">
                        <h6>
                          <strong>Email:</strong>
                          <br/>{coaches[1].fields[4].Email}
                        </h6>
                        <h6>
                          <strong>Location:</strong>
                          <br/>{coaches[1].fields[5].Location}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </CSSTransitionGroup>);
  }

}
