import React, {Component, useRef, ReactPropTypes} from 'react';
import NavBar from '../Header/NavBar';

import {Container, Row, Col} from 'react-bootstrap';
import './Log_In.scss';
import {Link, useHistory} from 'react-router-dom';


const clientId = '466372948941-sonmmj4gtbslcsr7aajk3dnqqqpjkolm.apps.googleusercontent.com'; //client ID registered to Caleb Kioh

export const googleConfig = clientId



class Log_In extends Component
{
  constructor(props){
    super(props);
    this.state = { email: '', password: ''};
    this.savePerameter = this.savePerameter.bind(this);
    this.handleLogIn = this.handleLogIn.bind(this);
  }
 
  onSuccess = (res) => 
  {
    console.log('[Login Sucess] currentUser:', res.profileObj);
  }
  onFailure = (res) =>
  {
    console.log('[Login Failed] res:', res);
  }

  handleLogIn(event)
  {
    console.log(this.state.password)
    console.log("this is a check")
    return event;
  }

  savePerameter(event, key)
  {
    this.setState({[key]: event.target.value})
    console.log(this.state.email)
  }

  render()
  {
  return(<div className = "screenHeight">

  <NavBar/>

  <div className = "bodyStyle">
  <div className = "log-in-box-padding"> </div>

  <div className="log-in-box">

      <form onSubmit={this.handleLogIn} action = "/Client_AccountSetup">
      <h1 style = {{textAlign:'left', fontSize:25, padding:25}}>Sign in</h1>
      <ul className = "log-in-box-list">
        <div className = "elementPadding">
        <li className = "emailLine">
          <t1 className = "listLineLeft"> Email  </t1> 
          <t2 className = "listLineRight">Don't have an account? 
            <Link className = "forgotPassword"  to='/Create_Account'> Sign up</Link>
          </t2> 
        </li>
        <li> <input className = "login-form" type="email" defaultValue={this.state.email} onChange={event=> this.savePerameter(event, 'email')} required/> </li>
        <li className = "emailLine">
          <t1 className = "passwordLineLeft"> Password  </t1> 
          <t2 className = "passwordLineRight">
            <Link className = "forgotPassword" style = {{fontSize:"8pt"}}>Forgot Password?</Link>
          </t2>
        <li><input className = "login-form" type="password" defaultValue={this.state.password} onChange={event=> this.savePerameter(event, 'password')} required/></li>
        </li>
        </div>
        <div style={{paddingBottom:40}}>
          <li><input className = "log-in-button" type="submit" value="Log in"/></li>
        </div>
        <li><hr className = "log-in-box-seperator"></hr></li>
      </ul>

      <Col ld={12}>
     <div>
 \
    

        </div>
      </Col>
      </form>
      </div>
      </div>
  </div>);
  }
}
export default Log_In;







