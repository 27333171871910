var coaches = [
    {
        id: 0,
        fields: [{ Name: "Holly Morphew" },
            { summary: "Certified Financial Health Counselor, Student Loan Counselor, and Identity Theft Risk Management Specialist" },
            { experience: "13 years" },
            { Certifications: " AFC, CFHC, CSLC, CITRMS" },
            { Email: "holly@financialimpact.com" },
            { Website: "financialimpact.com" },
            { Location: "Boulder, CO" }]
       },

    {
           id: 1,
           fields: [{ Name: "Yannek Smith" },
               { summary: "Accredited Financial Counselor, Credit Builders Alliance Master Trainer, and MA in Public Administration" },
               { experience: "3 years as bilingual coach" },
               { Certifications: "AFC" },
               { Email: "yanneksmith@gmail.com" },
               { Location: "Denver, CO" }]
        }

];

export default coaches;
