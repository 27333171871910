    import React from 'react';
import PropTypes from 'prop-types';


function QuestionCount(props) {
  return (
      <div className="questionCount">
          <div className="progress">
              <div className="progress-bar " style={{ width: `${(props.counter / props.total) * 100}%` }}>
                  
              </div>
          </div>
      Question <span >{props.counter}</span> of <span>{props.total}</span>
    </div>
  );
}


QuestionCount.propTypes = {
  counter: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
};

export default QuestionCount;
